import * as React from "react";
import { Route, Switch } from "react-router-dom";
import CedulaQr from "./cedulaqr";
import CedulaQrForm from "./cedulaqr/CedulaQrForm";
import CedulaQrUI from "./cedulaqr/CedulaQrUI";
import ExpedienteUI from './ExpedienteUI';
import ListadoExpedientes from './ListadoExpedientes';

const Expedientes = ({ match }) => {
    return (
      <Switch>
        <Route exact path={`${match.path}/:id/cedulaqr/:cedulaid`} render={() => <CedulaQrUI/>} />
        <Route exact path={`${match.path}/:id/cedulaqr`} render={() => <CedulaQrForm/>} />
        <Route exact path={`${match.path}/:id`} render={() => <ExpedienteUI/>} />
        <Route exact path={match.path} component={ListadoExpedientes} />
      </Switch>
  )};
export default Expedientes