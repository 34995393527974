import {
    BusquedaExpedientesState, 
    SET_BUSQUEDA_STRING,
    BusquedaExpedientesActionTypes,
    SET_BUSQUEDA_SKIP,
    SET_BUSQUEDA_ORGANISMO,
    SET_BUSQUEDA_STRING_ORGANISMO
} from './types'

const initialState: BusquedaExpedientesState = {
    organismo: "",
    busquedaString: "",
    skip: 10
  };
  
  
export default function busquedaExpediente(state = initialState, action: BusquedaExpedientesActionTypes): BusquedaExpedientesState {
    switch (action.type) {
        case SET_BUSQUEDA_STRING: {
            return {
                ...state,
                busquedaString: action.payload
            }
        }
        case SET_BUSQUEDA_SKIP: {
            return {
                ...state,
                skip: action.payload
            }
        }
        case SET_BUSQUEDA_ORGANISMO: {
            return {
                ...state,
                organismo: action.payload
            }
        } 
        case SET_BUSQUEDA_STRING_ORGANISMO: {
            return {
                ...state,
                organismo: action.payload.organismo,
                busquedaString: action.payload.busquedaString,
            }
        }        
        default:
            return state;
    }
}
  
    