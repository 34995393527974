import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withSize } from 'react-sizeme'
import { connect } from "react-redux";

import {
  Grid,
  VirtualTable,
  Toolbar,
  Table,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  DataTypeProvider, PagingState, CustomPaging,
} from "@devexpress/dx-react-grid";

import { WithStyles, createStyles } from "@material-ui/core";

import withRouter from "react-router/withRouter";
import CaratulaExpediente from "../../componentes/CaratulaExpediente";
import { UserProps } from "../../componentes/user.props.interface";
import Loading from "../../componentes/Loading"; 
import FiltroExpedientes from "../../componentes/table/FiltroExpedientes";
import { BusquedaExpedientesState } from "../../store/busquedaExpedientes/types";
import { setBusquedaSkip } from "../../store/busquedaExpedientes/actions";
//import { INDICE_ELASTIC } from "../../config";


const MAX_ROWS = 0;
const PAGE_SIZE = 150;
interface Props extends WithStyles<typeof styles> {
  user: UserProps;
  busquedaExpediente: BusquedaExpedientesState;
  expedientes: any;
  history: any;
  match: any;
  size: { 
    width: number, 
    height: number
  }
  onSkipChange: (skip: number) => void;
}
interface State  {
  token?: string
  skip: number;
  currentPage: number
}

class ListadoExpedientes extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      skip:0,
      currentPage:0
    };
    this.currentPageChangeHandler = this.currentPageChangeHandler.bind(this);
  }

  currentPageChangeHandler(nroPagina: number) {
    const skip = nroPagina*PAGE_SIZE;
    window['grecaptcha'].execute('6Lerc7QUAAAAAIsxq8l9js4UJow0NBUNjCWDwpEh', {action: 'buscar_expediente'}).then((token) => {
      this.setState({skip, token, currentPage: nroPagina})
    });
  }

  componentWillReceiveProps() {
    this.setState({token: undefined, skip: 0, currentPage: 0});
  }
  
  render() {
    let { busquedaString, organismo } = this.props.busquedaExpediente;
    const { token, skip, currentPage } = this.state;
    let cargandoToken = false;
    if (!token) {
      if (busquedaString && organismo) {
        cargandoToken = true
        setTimeout(() => this.currentPageChangeHandler(skip/PAGE_SIZE))
      }
      busquedaString = "";
      organismo = "";
      
    }

    let rows = []
    
    return (
      <div className="listado-expedientes" style={{flexGrow: 1, height: '100%'}}>
        <Query
          //query={ListadoExpedientes.LISTAR_EXPEDIENTES}
          //variables={{ buscar: busquedaString, organismo, take: PAGE_SIZE, skip, token }}
          query={ListadoExpedientes.LISTAR_EXPEDIENTES_ELASTIC}
          variables={
            { 
              filtro: {
                buscar: busquedaString,
                id_organismo: organismo
              },
              skip,
              first: PAGE_SIZE, 
              token               
            }
          }
        >
          {({ loading, error, data }) => {
            let noDataMsg = 'Cargando ..'
            if (!loading) {
              noDataMsg = "Sin datos"
            }
            if (error) {
              let errorMsg = JSON.stringify(error)
              if(errorMsg.indexOf('Store reset while') == -1) {
                console.error(errorMsg);
                return <p>Error :( {}</p>;
              }
              else 
                return  <Loading />

            }
            //let edges =  data.expedientesConnection && data.expedientesConnection.edges || []
            let edges =  data.buscarExpedientesElasticMesaVirtual && data.buscarExpedientesElasticMesaVirtual.edges || []
            rows = edges.map(edge => edge.node);
            //let totalCount = data.expedientesConnection && data.expedientesConnection.aggregate.count || MAX_ROWS;
            let totalCount = data.buscarExpedientesElasticMesaVirtual && data.buscarExpedientesElasticMesaVirtual.aggregate.count || MAX_ROWS;
            if (!organismo ) {
              noDataMsg = "Seleccione un organismo y realice su búsqueda";
              rows = [];
              totalCount = 0;
            }
            if (cargandoToken) {
              rows = [];
              totalCount = 0;
              noDataMsg = 'Cargando ..'
            }

            return (
              <Grid
                rows={rows}
                columns={[
                  { name: "caratula", title: "Expediente" },
                ]}
                getRowId={getRowId}
                
              >
                <CaratulaProvider for={["caratula"]} />
                <PagingState
                  currentPage={currentPage}
                  defaultPageSize={PAGE_SIZE}
                  onCurrentPageChange={this.currentPageChangeHandler}
                />
                <CustomPaging totalCount={totalCount} />
                <Table  messages={{noData: noDataMsg}} containerComponent={TableContainer}/>
                <PagingPanel />

                <Toolbar />
                <FiltroExpedientes />
                {loading && <Loading />}
              </Grid>
            );
          }}
        </Query>
      </div>
    );
  }

  
  static LISTAR_EXPEDIENTES = gql`
    query expedientes($buscar: String,  $organismo: ID, $take: Int, $skip: Int, $token: String) {
      expedientesConnection(first: $take, skip: $skip, filtro: { buscar: $buscar, id_organismo: $organismo }, token: $token) {
        aggregate {
          count
        }
        edges {
          node {
            id
            estado_salida
            nro {
              exp0
              exp1
              exp2
              exp3
            }
            caratula
            tipo_proceso
            inicio
            organismo
            circunscripcion
            localidad
          }
        }
      }
    }
  `;

  static LISTAR_EXPEDIENTES_ELASTIC = gql`
    query buscarExpedientesElasticMesaVirtual($first: Int, $skip: Int, $filtro: FiltroExpedientes, $token: String) {
      buscarExpedientesElasticMesaVirtual(first: $first, skip: $skip, filtro: $filtro, token: $token) {
        aggregate {
          count
        }
        edges {
          node {
            id
            estado_salida
            nro {
              exp0
              exp1
              exp2
              exp3
            }
            caratula
            tipo_proceso
            organismo
            circunscripcion
            localidad
            #inicio
            #grupo
            
            #justiciables {
            #  abogados {
            #    lex_id
            #    nombre_completo
            #  }
            #}
          }
        }
      }
    }
  `
}

const styles = () => createStyles({});

function TableContainer(props) {
  return  <Table.Container {...props} style={{order: 2, height: 'calc(80vh - 70px)'}} />
}


const CaratulaFormatter = ({ row }) => {
  return (
    <CaratulaExpediente expediente={row} clickeable={true}></CaratulaExpediente>
  );
};
const CaratulaProvider = props => (
  <DataTypeProvider
    formatterComponent={CaratulaFormatter}
    {...props}
  />
);

const getRowId = row => row.id;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    busquedaExpediente:  state.busquedaExpediente
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    onSkipChange: (skip: number) => dispatch(setBusquedaSkip(skip)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSize()(ListadoExpedientes)));
