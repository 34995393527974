import * as React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import dateFormat from "dateformat";

export const dateTimeFormatter = ({ value }) => {
  if(!value) {
    return '';
  }
  let fechaHora = new Date(value);
  return dateFormat(fechaHora, "dd/mm/yyyy HH:MM") + " hs";
};
export const dateFormatter = ({ value }) => {
  if(!value) {
    return '';
  }
  let fechaHora = new Date(value);
  return dateFormat(fechaHora, "dd/mm/yyyy");
};

export let DateTimeTypeProvider = props => (
  <DataTypeProvider formatterComponent={dateTimeFormatter} {...props} />
);

export let DateTypeProvider = props => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);
