import * as React from 'react';
import { WithStyles, withStyles, createStyles, Theme, Paper, Grid, Fab } from '@material-ui/core';



interface Props extends WithStyles<typeof styles> {
    cedula: any
}
interface State {

}
class CabeceraCedulaQRComponente extends React.Component<Props, State> {
    render() {
        const { classes, cedula } = this.props;
        return (
            <Paper className={classes.root} elevation={1}>
                        <h3>{`Cédula QR #${cedula.id}`}</h3>
                       <p>Emitida en fecha: {new Date(cedula.fecha).toLocaleString()}</p>
                       <div dangerouslySetInnerHTML={{__html: cedula.descripcion}} />
            </Paper>
        )
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        padding: '0.5em',
    },
    accionesExpediente: {
        margin: theme.spacing(),
        borderRadius: '0.7em',
        maxHeight: '2.4em',
        minWidth: '2em',
        minHeight: '0.5em',
        backgroundColor: 'ligthgray',
        padding: '0.5em 4em 3em 4em'
    },
})
export var CabeceraCedulaQR = withStyles(styles)(CabeceraCedulaQRComponente);