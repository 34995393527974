import {
  createStyles,
  Theme, WithStyles,
  withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { RouteComponentProps } from "react-router-dom";
import withRouter from "react-router/withRouter";
import Loading from "../../../componentes/Loading";
import { CabeceraCedulaQR } from "./CabeceraCedulaQr";
import FileIcon, { defaultStyles } from 'react-file-icon';
import DownloadIcon from '@material-ui/icons/Archive'
import { API_ARCHIVOS } from "../../../config";

interface Props extends WithStyles<typeof styles> { }

interface State {
  openOpcionesAgregar: boolean;
  updateTime: number;
  token?: string;
}

const estilos = defaultStyles

class CedulaQrUI extends React.Component<
  Props & RouteComponentProps<{ id: string, cedulaid: string }>,
  State
> {

  constructor(props) {
    super(props);
    this.state = {
      openOpcionesAgregar: false,
      updateTime: Date.now(),
      token: undefined
    }
  }

  handleVisualizar(values) {
    console.log("The Values that you wish to edit ", values);
  };
  // componentWillMount() {
  //   window['grecaptcha'].execute('6Lerc7QUAAAAAIsxq8l9js4UJow0NBUNjCWDwpEh', {action: 'cedula_qr'}).then((token) => {
  //     this.setState({...this.state, token });
  //   });
  // }
  render() {
    const { classes } = this.props;
    const expedienteId = this.props.match.params.id;
    const cedulaid = this.props.match.params.cedulaid;
    const { token } = this.state;

    // let cargandoToken = false;
    // if (!token) {
    //   cargandoToken = true     
    //   return <Loading />
    // }

    return (
      <div className={classes.root} >
        <Query
          query={CedulaQrUI.DETALLE_CEDULAQR}
          variables={{ expedienteId: expedienteId, cedulaQrId: cedulaid, token: token }}
        >
          {({ subscribeToMore, loading, error, data }) => {
            if (loading) {
              return <Loading />
            }
            if (error) {
              let errorMsg = JSON.stringify(error)
              if (errorMsg.indexOf('Store reset while') == -1)
                return errorMsg;
              else
                return <Loading />
            }
            const { cedulaQr } = data

            return (
              <div className={classes.contenido} >
                <CabeceraCedulaQR cedula={cedulaQr} />
                <Table aria-label="Movimientos cédula">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha y hora</TableCell>
                      <TableCell>Descripción</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cedulaQr.movimientos.map((mov,index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {new Date(mov.fecha_hora).toLocaleString()}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {mov.descripcion}
                        </TableCell>
                        <TableCell align="right">
                          {/* <Button
                            aria-label="visualizar"
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleVisualizar(mov)}
                          >
                            Visualizar
                          </Button> */}
                          {
                                    mov.archivo.tipo && (
                                        <div style={{margin: 0, padding: 0}}>
                                            <a 
                                                href={`${API_ARCHIVOS}/archivo/${expedienteId}/${mov.archivo.id}?format=pdf`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                
                                                <FileIcon
                                                    extension={mov.archivo.tipo}
                                                    {...estilos[mov.archivo.tipo.toLowerCase()]}
                                                    size={24}
                                                    />
                                            </a>
                                            <a 
                                                style={{color: "black"}}
                                                href={`${API_ARCHIVOS}/archivo/${expedienteId}/${mov.archivo.id}`}
                                                download
                                                >
                                                
                                                <DownloadIcon />
                                            </a>
                                        </div>
                                    )
                            }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
  static DETALLE_CEDULAQR = gql`
    query cedulaQr($expedienteId: String!, $cedulaQrId: String!, $token: String ) {
      cedulaQr(expedienteId: $expedienteId, cedulaQrId: $cedulaQrId, token: $token) {
        id
        fecha
        descripcion
        path
        movimientos {
          descripcion
          fecha_hora
          archivo {
            id
            tipo
            size
            texto
          }
      }
    }
    }
  `;

}


const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: theme.spacing(),
    },
    contenido: {
      display: 'flex',
      flexDirection: 'column',
    },
    actionButton: {
      textDecoration: "none"
    },
    opcionesAgregarEntrada: {
      margin: theme.spacing(2)
    }
  });
export default withStyles(styles)(withRouter(CedulaQrUI));
