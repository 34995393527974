import { ApolloLink } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { API_URL, API_WS } from "./config";

export function getApolloClient() {
    let token = localStorage.getItem("token");

    const uploadLink = createUploadLink({ uri: API_URL });
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers
        }
      };
    });
    const httpLink = authLink.concat(uploadLink)
    let apolloClient = new ApolloClient({
      ssrMode: typeof window !== "undefined",
      cache: new InMemoryCache(),
      link: httpLink
    });
    return apolloClient;    
}
