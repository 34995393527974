import user from "./usuario/user";
import busquedaExpediente from "./busquedaExpedientes/reducers";
import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';


let reducers = combineReducers({
  user,
  busquedaExpediente
})

export let store = createStore(
    reducers,
    composeWithDevTools()
);


