import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from "react-dom";
import "./index.css";
import RootApp from "./RootApp";


ReactDOM.render(<RootApp />, document.getElementById("root"));

