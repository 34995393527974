import * as React from 'react';
import { WithStyles, withStyles, createStyles, Theme} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
interface Props extends WithStyles<typeof styles> {
    
}
interface State {
    
}
class Loading extends React.Component<Props, State> {
    render() {
        let { classes } = this.props;
        return(
            <div className={ classes.loadingShadingMui }>
                <CircularProgress className={ classes.loadingIconMui } />
            </div>
        )    
    }
}
const styles = (theme: Theme) => createStyles({
    loadingShadingMui: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, .3)',
      },
      
    loadingIconMui: {
        position: 'absolute',
        fontSize: '20px',
        top: 'calc(45% - 10px)',
        left: 'calc(50% - 10px)'
    }
})
export default withStyles(styles)(Loading);