import * as React from 'react';
import Select from 'react-select';
import { Query } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { WithStyles, withStyles, createStyles, Theme, Paper, Grid, InputBase, Button} from '@material-ui/core';
import {
  Template, TemplatePlaceholder, Plugin, TemplateConnector,
} from '@devexpress/dx-react-core';
import { BusquedaExpedientesState } from '../../store/busquedaExpedientes/types';
import { setBusquedaString, setBusquedaOrganismo, setBusquedaStringOrganismo } from '../../store/busquedaExpedientes/actions';
import { naturalCompare } from './naturalCompare';
import { valueContainerCSS } from 'react-select/lib/components/containers';

export interface IOrganismoSeleccionado {
  value: string
  label: string
}
interface Props extends WithStyles<typeof styles> {
  onBuscarChange: (busquedaExpediente: BusquedaExpedientesState) => void
  busquedaExpedienteProps: BusquedaExpedientesState
}

interface State extends BusquedaExpedientesState {
}

const estilosSelectOrganismo = {
  control: (styles) => ({
    ...styles,
    height: 46,
  }),
}



class FiltroExpedientes extends React.PureComponent<Props, State> {

  constructor(props) {
    super(props);
    let { busquedaExpedienteProps } = this.props;
    this.state = {
      busquedaString: busquedaExpedienteProps.busquedaString,
      organismo: busquedaExpedienteProps.organismo,
      skip: 0
    }
  }

  handleBuscarExpedientes() {
    let self = this;
    self.props.onBuscarChange(self.state);
  
  }

  render() {
    let { classes, busquedaExpedienteProps } = this.props;
    let estadoBusqueda = this.state;
    return (
      <Plugin name="FiltroExpedientes">
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <TemplateConnector>
            {({}) => (
              <React.Fragment>

                <Query query={FiltroExpedientes.LISTAR_ORGANISMOS}>
                {({ loading, error, data }) => {
                  let opciones: {value:string, label: string}[] = []
                  
                  if(!loading && !error) {
                    let organismosOpciones = data.organismos.map(organismo => (
                      {
                        label: `${organismo.jurisdiccion} - ${organismo.nombre}`,
                        value: organismo.id
                      }
                      ))
                      
                      let organismosOrdenados =  organismosOpciones.sort((a,b) => {
                        return naturalCompare(a.label, b.label)
                      })
                    opciones.push(...organismosOrdenados)
                  }
                  let buscarString = estadoBusqueda.busquedaString || busquedaExpedienteProps.busquedaString
                  let seleccionOrganismo = opciones.find(op => {
                    const valor = op.value===(!!estadoBusqueda.organismo && estadoBusqueda.organismo) || (!estadoBusqueda.organismo && busquedaExpedienteProps.organismo && op.value==busquedaExpedienteProps.organismo)
                    return valor;
                  })
                  return (
                          <Grid
                            container
                            className={classes.contenedor}
                            spacing={1}
                            direction="row"
                           >
                            <Grid item xs={12} md={6}>
                              <Select
                                isSearchable
                                placeholder="Seleccione un organismo"
                                options={opciones}
                                menu
                                styles={estilosSelectOrganismo}
                                onChange={(seleccion: any) => this.setState({organismo: seleccion.value})}
                                value={seleccionOrganismo}
                                />
                            </Grid>
   
                            <Grid item xs={12} md={6}>
                              <Paper className={classes.buscar} elevation={0}>
                                <InputBase 
                                  className={classes.input}
                                  placeholder="Buscar"
                                  defaultValue = {busquedaExpedienteProps.busquedaString}
                                  onChange= {(e) => this.setState({busquedaString: e.target.value})}
                                  onKeyPress={ev => {
                                  if (ev.key === "Enter") {
                                    this.handleBuscarExpedientes()
                                    }
                                  }}
                              /> 
                              <Button
                                variant="contained"
                                color="primary"
                                aria-label="Buscar"
                                onClick={() => this.handleBuscarExpedientes()}
                              >
                                Buscar
                              </Button>
                              </Paper>
                          </Grid>
                        </Grid>
                          )
                }}
                </Query>

              </React.Fragment>
            )}
          </TemplateConnector>
        </Template>
      </Plugin>
    )
  }

  static LISTAR_ORGANISMOS = gql`
    {
      organismos {
        id
        codigo
        jurisdiccion
        nombre
      }
    }
  `;
  
}
const styles = (theme: Theme) => createStyles({
  contenedor: {
    flexGrow: 1
  },
  selector: {
    paddingRight: theme.spacing() * 4
  },
  input: {
    marginLeft: 8,
    flex: 1,
    minHeight: '44px',
  },
  iconButton: {
    padding: 15,
  },
  buscar: {
    display: 'flex',
    alignItems: 'center',
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
})

const mapStateToProps = (state) => {
  return {
    busquedaExpedienteProps:  state.busquedaExpediente
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    onBuscarChange: (busquedaExpediente: BusquedaExpedientesState) => {
      dispatch(setBusquedaStringOrganismo(busquedaExpediente.busquedaString, busquedaExpediente.organismo))
    } 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FiltroExpedientes));