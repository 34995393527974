import * as React from 'react';
import { WithStyles, withStyles, createStyles, Theme, Grid, Paper} from '@material-ui/core';
import { TipoProceso } from './tipo.proceso';
import { Link } from 'react-router-dom';

interface Props extends WithStyles<typeof styles> {
    expediente: any,
    clickeable?: boolean,
    mostrarInicio?: boolean,
    resaltarCaratula?: boolean
}
interface State {
    
}
class CaratulaExpediente extends React.Component<Props, State> {
    render() {
        let { expediente, mostrarInicio, clickeable, resaltarCaratula, classes } = this.props;
        if(!expediente) return <span>Sin Datos Expediente</span>
        let fontWeight : 'bold' | 'inherit' = resaltarCaratula ? 'bold' : 'inherit'
        return (
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                    {clickeable && <Link to={`/expedientes/${expediente.id}`} className={classes.caratula}>
                        {expediente.estado_salida && <div style={{backgroundColor: 'lightpink'}}>NL: {expediente.estado_salida}</div>}
                        <span style={{overflowWrap: "break-word", whiteSpace: "normal", fontWeight}}> {expediente.caratula} </span>
                        <TipoProceso tipo_proceso={expediente.tipo_proceso}> </TipoProceso>
                        {mostrarInicio && <div>inicio: { dateFormatter({value: expediente.inicio}) }</div>}
                    </Link>}
                    {!clickeable && <div className={classes.caratula}>
                        {expediente.estado_salida && <div style={{backgroundColor: 'lightpink'}}>NL: {expediente.estado_salida}</div>}
                        <span style={{overflowWrap: "break-word", whiteSpace: "normal", fontWeight}}> {expediente.caratula} </span>
                        <TipoProceso tipo_proceso={expediente.tipo_proceso}> </TipoProceso>
                        {mostrarInicio && <div>inicio: { dateFormatter({value: expediente.inicio}) }</div>}
                    </div>}
                </Grid>
                <Grid item xs={12} sm={2}>
                    <div>{ nroFormatter(expediente.nro) }</div>
                </Grid>
                <Grid item xs={"auto"} sm={1} style={{marginRight: '1em'}}>
                    <div>{expediente.circunscripcion}</div>
                </Grid>
                <Grid item xs={"auto"} sm={2}>
                    <div style={{wordBreak: "break-word", whiteSpace:"normal"}}>{expediente.organismo}</div>
                </Grid>
            </Grid>
        )
    }
}
const styles = (theme: Theme) => createStyles({
    estadoSalida: {
        color: theme.palette.error.main,
        fontSize: 12,
        marginLeft: -8,
    },
    caratula: {
        textDecoration: 'none',
        color: 'black',
    }
})

const dateFormatter = ({ value }) => {
    return new Date(value).toLocaleDateString();
};
const nroFormatter = ({ exp0, exp1, exp2, exp3 }) => {
    let nro = [exp0, exp1, exp2, exp3];
    return nro.map((exp, index) => exp ? <div style={{wordBreak: 'break-all'}} key={index}> <b>exp{index}: </b><span>{exp}</span> </div> :  null )
        .filter(exp => exp!= null)
};
export default withStyles(styles)(CaratulaExpediente);