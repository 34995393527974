import { Button, createStyles, Grid, InputBase, Paper, withStyles, WithStyles } from "@material-ui/core";
import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { Redirect, Route, RouteComponentProps } from "react-router";
import withRouter from "react-router/withRouter";
import { withSize } from 'react-sizeme';
import Loading from "../../../componentes/Loading";
import InputMask from 'react-input-mask';

interface State {
  token?: string;
  busquedaString?: string;
  buscarTexto?: string;
}

interface Props extends WithStyles<typeof styles> {
  history: any;
  match: any;
  size: {
    width: number,
    height: number
  }
}

class CedulaQrForm extends React.Component<Props & RouteComponentProps<{ id: string, cedulaid: string }>, State> {

  constructor(props) {
    super(props);
    this.state = {
      busquedaString: "",
      buscarTexto: "",
    }
  }

  keyPress(e) {
    if (e.key === "Enter") {
      this.handleBuscarCedualaQr();
    }
  }

  handleBuscarCedualaQr() {
    window['grecaptcha'].execute('6Lerc7QUAAAAAIsxq8l9js4UJow0NBUNjCWDwpEh', {action: 'buscar_cedula_qr'}).then((token) => {
      this.setState({ buscarTexto: this.state.busquedaString, token });
    });
  }

  render() {
    //const expedienteId = this.props.match.params.id;
    let { classes } = this.props;
    const { token, buscarTexto } = this.state;
    let cargandoToken = false;
    if (!token) {
      if (buscarTexto) {
        cargandoToken = true
      }
    }
    return (
      <div className="listado-expedientes" style={{ flexGrow: 1, height: '100%' }}>
        <Query
          query={CedulaQrForm.CEDULAQR_BUSCAR}
          variables={{ idCorto: buscarTexto, token: token }}
        >
          {({ loading, error, data }) => {
            let noDataMsg = 'Cargando ..'
            if (!loading) {
              if (cargandoToken) {
                noDataMsg = 'Cargando ..'
              }
              noDataMsg = "Sin datos"
            }
            if(loading)
              return <Loading />
            if (error) {
              let errorMsg = JSON.stringify(error)
              if (errorMsg.indexOf('Store reset while') === -1) {
                return <p>Error :( { }</p>;
              }
              else
                return <Loading />
            }

            if (cargandoToken) {
              noDataMsg = 'Cargando token..'
            }
            if (data.cedulaQRBuscar) {
              return (
                <Route exact path="/cedulaqr">
                  <Redirect to={`/expedientes/${data.cedulaQRBuscar.expedienteId}/cedulaqr/${data.cedulaQRBuscar.cedulaId}`} />
                </Route>
              )
            }
            return (

              <Grid
                container
                className={classes.contenedor}
                spacing={1}
                direction="column"
                alignContent="center"
              >
                <Grid item xs={12} md={12}>
                  <img className={classes.imagen} src="/qr_buscar.png" alt="" />
                  <InputMask mask='********' maskChar="">
                    {(inputProps) => (
                      <Paper className={classes.buscar} elevation={0}>

                        <InputBase
                          className={classes.input}
                          placeholder="Buscar"
                          defaultValue={buscarTexto}
                          onChange={(e) => this.setState({ busquedaString: e.target.value })}
                          onKeyPress={(e) => this.keyPress(e)}
                          {...inputProps}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          aria-label="Buscar"
                          onClick={() => this.handleBuscarCedualaQr()}
                        >
                          Buscar
                        </Button>
                      </Paper>
                    )}
                  </InputMask>
                  
                  {!data.cedulaQRBuscar && !loading && !error && !(buscarTexto === "") && <p>No se encuentra la CédulaQR </p>}
                </Grid>
              </Grid>
            );
          }}
        </Query>
      </div>
    );
  }

  static CEDULAQR_BUSCAR = gql`
    query cedulaQRBusqueda($idCorto: String!, $token: String){
      cedulaQRBuscar(id_corto: $idCorto, token: $token){
        expedienteId
        cedulaId 
      }
    }
  `;
}

const styles = () => createStyles({
  imagen: {
    paddingBottom: "2rem",
    maxWidth: "50vh",
  },
  contenedor: {
    flexGrow: 1,
    padding: "2rem",

  },
  input: {
    marginLeft: 8,
    flex: 1,
    minHeight: '44px',
  },
  iconButton: {
    padding: 15,
  },
  buscar: {
    display: 'flex',
    alignItems: 'center',
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
});

export default withStyles(styles)(withRouter(withSize()(CedulaQrForm)));
