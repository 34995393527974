// src/store/chat/types.ts
export const SET_BUSQUEDA_STRING = "SET_BUSQUEDA_STRING";
export const SET_BUSQUEDA_SKIP = "SET_BUSQUEDA_SKIP";
export const SET_BUSQUEDA_ORGANISMO = "SET_BUSQUEDA_ORGANISMO";
export const SET_BUSQUEDA_STRING_ORGANISMO = "SET_BUSQUEDA_STRING_ORGANISMO";
export const SET_BUSQUEDA_FECHA = "SET_BUSQUEDA_FECHA";

export interface BusquedaExpedientesState {
  busquedaString: string;
  organismo: string;
  skip: number;
}
export enum RangoFecha {
  TodasLasFechas,
  UltimaSemana,
  UltimoMes,
  UltimoAnio
}
interface SetBusquedaStringAction {
  type: typeof SET_BUSQUEDA_STRING;
  payload: string;
}

interface SetBusquedaSkipAction {
  type: typeof SET_BUSQUEDA_SKIP;
  payload: number;
}

interface SetBusquedaOrganismoAction {
  type: typeof SET_BUSQUEDA_ORGANISMO;
  payload: string;
}

interface SetBusquedaFechaAction {
  type: typeof SET_BUSQUEDA_FECHA;
  payload: string;
}

export interface SetBusquedaStringOrganismoAction {
  type: typeof SET_BUSQUEDA_STRING_ORGANISMO;
  payload: {
    busquedaString: string,
    organismo: string
  };
}

export type BusquedaExpedientesActionTypes =
  | SetBusquedaStringAction
  | SetBusquedaSkipAction
  | SetBusquedaOrganismoAction
  | SetBusquedaFechaAction
  | SetBusquedaStringOrganismoAction;
