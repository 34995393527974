import {
  BusquedaExpedientesActionTypes,
  SET_BUSQUEDA_STRING,
  SET_BUSQUEDA_SKIP,
  SET_BUSQUEDA_ORGANISMO,
  SET_BUSQUEDA_FECHA,
  SET_BUSQUEDA_STRING_ORGANISMO,
  SetBusquedaStringOrganismoAction
} from "./types";

// TypeScript infers that this function is returning SendMessageAction
export function setBusquedaString(
  busquedaString: string
): BusquedaExpedientesActionTypes {
  return {
    type: SET_BUSQUEDA_STRING,
    payload: busquedaString
  };
}

export function setBusquedaSkip(skip: number): BusquedaExpedientesActionTypes {
  return {
    type: SET_BUSQUEDA_SKIP,
    payload: skip
  };
}


export function setBusquedaOrganismo(id_organismo: string): BusquedaExpedientesActionTypes {
    return {
      type: SET_BUSQUEDA_ORGANISMO,
      payload: id_organismo
    };
  }

export function setBusquedaFecha(busquedaFecha: string): BusquedaExpedientesActionTypes {
  return {
    type: SET_BUSQUEDA_FECHA,
    payload: busquedaFecha
  };
}

export function setBusquedaStringOrganismo(busquedaString: string, organismo: string): BusquedaExpedientesActionTypes {
  let action: SetBusquedaStringOrganismoAction =  {
    type: SET_BUSQUEDA_STRING_ORGANISMO,
    payload: {
      busquedaString,
      organismo
    }
  };
  return action;
}

