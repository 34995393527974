import * as React from 'react';
import { WithStyles, withStyles, createStyles, Theme, Paper, Grid, Fab } from '@material-ui/core';

interface Props extends WithStyles<typeof styles> {
    titulo: string | JSX.Element
    subtitulo?: string | JSX.Element
    acciones?: JSX.Element[]
}
interface State {

}
class CabeceraExpedienteComponente extends React.Component<Props, State> {
    render() {
        const { classes, titulo, subtitulo, acciones } = this.props;
        return (
            <Paper className={classes.root} elevation={1}>
                <Grid container direction="row" spacing={8}>
                    <Grid item xs>
                            {titulo}
                        {subtitulo && subtitulo }
                        
                    </Grid>
                    {acciones && <Grid container direction="row" justify="flex-end" spacing={8}>
                        {acciones.map((item, index) => React.cloneElement(item, {key: index}))}
                    </Grid>}
                </Grid>
                {this.props.children}
            </Paper>
        )
    }

}

export class BotonCabeceraComponente extends React.Component<WithStyles<typeof styles> & {
    onClick?: React.MouseEventHandler
    buttonRef?: React.Ref<any> | React.RefObject<any>
    }, State> {
    render() {
        const { classes, onClick, buttonRef } = this.props;
        return (
            <Fab onClick={onClick} buttonRef={buttonRef} size="small" className={classes.accionesExpediente}>
                {this.props.children}
            </Fab>
        )
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        padding: '0.5em',
    },
    accionesExpediente: {
        margin: theme.spacing(),
        borderRadius: '0.7em',
        maxHeight: '2.4em',
        minWidth: '2em',
        minHeight: '0.5em',
        backgroundColor: 'ligthgray',
        padding: '0.5em 4em 3em 4em'
    },
})
export var BotonCabecera = withStyles(styles)(BotonCabeceraComponente);
export var CabeceraExpediente = withStyles(styles)(CabeceraExpedienteComponente);