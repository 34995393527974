import * as React from "react";
import { WithStyles, withStyles, createStyles, Theme } from "@material-ui/core";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";

import App from "./App";
import { OS_APP_ID } from "./config";
import { store } from "./store";
import { getApolloClient } from "./apolloClient";
import Loading from "./componentes/Loading";

interface Props extends WithStyles<typeof styles> {}
interface State {
  client?: ApolloClient<any>
}

class RootApp extends React.Component<Props, State> {
  state: State = {
  };
  componentDidMount() {
    this.setState({ client: getApolloClient() });
  }
  render() {
    let client = this.state.client as ApolloClient<any>;

    if (client) {
      return (
        <Provider store={store}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </Provider>
      );
    } else {
      return <Loading />;
    }
  }
}
const styles = (theme: Theme) => createStyles({});
export default withStyles(styles)(RootApp);
