import * as React from 'react';
import toMaterialStyle from 'material-color-hash';
import { WithStyles, withStyles, createStyles, Theme} from '@material-ui/core';
import { isPending } from 'q';

interface Props extends WithStyles<typeof styles> {
    tipo_proceso: string
}
interface State {

}
class TipoProcesoComponente extends React.Component<Props, State> {
    render() {
        const {tipo_proceso, classes} = this.props
        let compontes = tipo_proceso.split(' -> ')
        let tipo = compontes[0]
        let padres: string[];
        let inicialesPadres: string[] = []
        if(compontes.length > 1) {
            padres = compontes[1].split(' - ')
            // inicialesPadres = compontes[1]
            inicialesPadres = padres.map(p => p.split(' ')
                .filter(com => !com.startsWith("("))
                .map(pc => pc[0]).join(''))
                .filter(ip => ip.trim().length > 0 )

        }
        return (
        <div style={{marginBottom: '0.5em'}}> 
            {
            inicialesPadres.map((ip, index) => {
              const style = toMaterialStyle(ip, 200);
              return <span style={style} key={index} className={classes.padreTipoProceso}>{ip}</span>
            })
            } {tipo} </div>
        )
    }

}



const styles = (theme: Theme) => createStyles({
    padreTipoProceso: {
        paddingLeft: "5px",
        paddingRight: "5px",
        border: "none",
        borderRadius: "16px",
        justifyContent: "center"
    }
})
export var TipoProceso = withStyles(styles)(TipoProcesoComponente);