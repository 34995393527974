import * as React from "react"
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import FileIcon, { defaultStyles } from 'react-file-icon';
import DownloadIcon from '@material-ui/icons/Archive'
import {
    Plugin,
    Template,
    Getter
} from "@devexpress/dx-react-core";
import { withRouter, RouteComponentProps } from "react-router";
import { API_ARCHIVOS } from "../../config";
const CONTENIDOS = 'contenidos';

interface IArchivo {
    id: String
    tipo: string
    size: Number
    texto: String
}
interface IExpediente {
    id: string
    caratula: string
}

const estilos = defaultStyles

estilos.rtf.color= '#CCCCCC'
estilos.rtf.labelColor= '#FF364E'

function isHeadingActionsTableCell(obj) {
    return obj.tableRow.type === TableHeaderRow.ROW_TYPE && obj.tableColumn.type === CONTENIDOS;
}

function isActionsTableCell(obj) {
    return obj.tableRow.type === Table.ROW_TYPE && obj.tableColumn.type === CONTENIDOS;

}
interface Props extends RouteComponentProps<any>{
    expediente: IExpediente
}
class TableActionsColumn extends React.PureComponent<Props> {
    render() {
        const { expediente } = this.props
        return (

            <Plugin>
                <Getter
                    name="tableColumns"
                    computed={({ tableColumns }) => {
                        const result = tableColumns.slice();
                        result.push({ key: CONTENIDOS, type: CONTENIDOS, width: 130 });
                        return result;
                    }}
                    />
                {/* Styling 'actions' column */}
                <Template
                    name="tableCell"
                    predicate={(obj) => isActionsTableCell(obj)} >
                    {(params: any) => {
                        let archivo: IArchivo =  params.tableRow.row.archivo
                        return <Table.Cell {...params} row={params.tableRow.row}>
                            {
                                    archivo.tipo && (
                                        <div style={{margin: 0, padding: 0}}>
                                            <a 
                                                href={`${API_ARCHIVOS}/archivo/${expediente.id}/${archivo.id}?format=pdf`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                
                                                <FileIcon
                                                    extension={archivo.tipo}
                                                    {...estilos[archivo.tipo.toLowerCase()]}
                                                    size={24}

                                                    />
                                            </a>
                                            <a 
                                                style={{color: "black"}}
                                                href={`${API_ARCHIVOS}/archivo/${expediente.id}/${archivo.id}`}
                                                download
                                                >
                                                
                                                <DownloadIcon />
                                            </a>
                                        </div>
                                    )
                            }
                        </Table.Cell>
                    }}
                </Template>
            </Plugin>

        )
    }
}

export default withRouter(TableActionsColumn)