import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import * as React from "react";
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withSize } from 'react-sizeme';
import AppBar from './AppBar';
import CedulaQr from "./paginas/expediente/cedulaqr";
import Expedientes from './paginas/expediente';
import CedulaQrForm from './paginas/expediente/cedulaqr/CedulaQrForm';


interface Props extends WithStyles<typeof styles> {
  size: {
    width: number
  }
}
interface State {
  appMenuOpen: boolean | null
  
}
class App extends React.Component<Props, State> {
  state: State = {
    appMenuOpen: null
  }
  handleAppMenuClose = () => {
    this.setState({ appMenuOpen: false });
  };
  handleAppMenuOpen = () => {
    this.setState({ appMenuOpen: true });
  };
  isMenuOpen() {
    const { size } = this.props;
    if(this.state.appMenuOpen == null) {
      return size.width > 1000
    } else {
      return this.state.appMenuOpen
    }
  }
  render() {
    const { classes, size } = this.props;
    return (
      <Router>
        <div className={classes.root}>
          <AppBar appMenuOpen={false}/>
          <main className={classes.content}>
            <Route exact path="/" component={Main} />
            <Route path="/expedientes" component={Expedientes} />
            <Route path="/cedulaqr" component={CedulaQrForm} />

          </main>
        </div>
      </Router>
    )
  }
}
function Main() {
  return (<Redirect to="/expedientes" />);
}

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    width: 'calc(100% - 350px)'
  }
})
export default withSize()(withStyles(styles)(App));
