import * as React from "react";
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow
} from "@devexpress/dx-react-grid-material-ui";


import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Popover
} from "@material-ui/core";


import { RouteComponentProps } from "react-router-dom";

import withRouter from "react-router/withRouter";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import TableEntradasExpediente from "../../componentes/table/TableEntradasExpediente";
import {
  CabeceraExpediente,
  BotonCabecera
} from "../../componentes/cabeceraExpediente";
import { DateTypeProvider } from "../../componentes/table/DateTypeProvider";
import CaratulaExpediente from "../../componentes/CaratulaExpediente";
import Loading from "../../componentes/Loading";

interface Props extends WithStyles<typeof styles> {}

interface State {
  tableColumnExtensions: { columnName: string; width: number }[];
  openOpcionesAgregar: boolean;
  updateTime: number;
  token?: string;
}

class ExpedienteUI extends React.Component<
  Props & RouteComponentProps<{ id: string }>,
  State
> {
  anchorEl: HTMLElement | null = null;
  state = {
    tableColumnExtensions: [{ columnName: "fecha_hora", width: 150 }],
    openOpcionesAgregar: false,
    updateTime: Date.now(),
    token: undefined
  };
  componentWillMount() {
    window['grecaptcha'].execute('6Lerc7QUAAAAAIsxq8l9js4UJow0NBUNjCWDwpEh', {action: 'detalle_expediente'}).then((token) => {
      this.setState({...this.state, token });
    });
  }
  
  render() {    
    const { classes} = this.props;
    const expedienteId = this.props.match.params.id;
    const { tableColumnExtensions, token } = this.state;

    let cargandoToken = false;
    if (!token) {
      cargandoToken = true     
      return <Loading />
    }
    
    return (
      <div className={classes.root} >
        <Query
          query={ExpedienteUI.DETALLE_EXPEDIENTE}
          variables={{ id: expedienteId, token: token }}
        >
          {({ subscribeToMore, loading, error, data }) => {
            let noDataMsg = 'Cargando ..'
            if (!loading) {
              if (cargandoToken) {
                noDataMsg = 'Cargando ..'
              }
              noDataMsg = "Sin datos"
            }
            if (loading) {
              return <Loading />
            }
            if (error) {
              let errorMsg = JSON.stringify(error)
              if(errorMsg.indexOf('Store reset while') == -1) 
                return errorMsg;
              else 
                return  <Loading />

            }
            const { expediente } = data;
            return (
              <div className={classes.contenido} >
                <CabeceraExpediente
                  titulo={ <CaratulaExpediente expediente={expediente} mostrarInicio={true}></CaratulaExpediente> }
                  // acciones={acciones}
                >
                </CabeceraExpediente>
                <TableGrid
                  rows={expediente.movimientos}
                  columns={[
                    { name: "fecha_hora", title: "Fecha" },
                    { name: "descripcion", title: "Descripción" },
                  ]}
                >
                  <DateTypeProvider for={["fecha_hora"]} />
                  <Table messages={{noData: noDataMsg}} columnExtensions={tableColumnExtensions} />
                  <TableHeaderRow />
                  <TableEntradasExpediente expediente={expediente} />
                </TableGrid>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
  static DETALLE_EXPEDIENTE = gql`
    query expediente($id: ID!, $token: String) {
      expediente(id: $id, token: $token) {
        id
        caratula
        tipo_proceso
        inicio
        estado_salida
        nro {
          exp0
          exp1
          exp2
          exp3
        }
        organismo
        circunscripcion
        localidad
        movimientos {
          fecha_hora
          descripcion
          archivo {
            id
            tipo
            size
            texto
          }

        }
      }
    }
  `;

}


const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: theme.spacing(),
    },
    contenido: {
      display: 'flex',
      flexDirection: 'column',
    },
    actionButton: {
      textDecoration: "none"
    },
    opcionesAgregarEntrada: {
      margin: theme.spacing(2)
    }
  });
export default withStyles(styles)(withRouter(ExpedienteUI));
